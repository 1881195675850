import React, { useEffect, useState } from "react";
import { useParams, useHistory, Route, useLocation } from "react-router-dom";
import axios from "axios";
import QueryString from "qs";

import Searchbar from "./components/Searchbar/Searchbar";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Card from "./components/Card/Card";
import Intro from "./components/Intro/Intro";
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker";

import "./App.css";
import Present from "./components/Present/Present";
import Status from "./components/Status/Status";
import Slidebar from "./components/Slidebar/Slidebar";

function App() {
  let [선물정보, 선물정보변경] = useState({});
  let [showCerti, setShowCerti] = useState(false);

  let history = useHistory();
  let location = useLocation();

  return (
    <div className="App">
      <RouteChangeTracker></RouteChangeTracker>

      <Route exact path="/">
        <div className="body-container height-80vh">
          <Header></Header>
          <Slidebar></Slidebar>
          <Searchbar 선물정보변경={선물정보변경}></Searchbar>
          <Footer></Footer>
        </div>
        {/* <Present 선물정보={선물정보}></Present> */}
      </Route>

      <Route exact path="/about">
        <Header></Header>
        <Intro></Intro>
      </Route>

      <Route exact path="/certification">
        <Header></Header>
        <Card 선물정보={선물정보}></Card>
      </Route>

      <Route exact path="/present">
        <Header></Header>
        <div style={{ position: "relative" }}>
          <Present 선물정보={선물정보}></Present>
          <Status 현황={선물정보.status} setShowCerti={setShowCerti}></Status>
          {showCerti && (
            <Card
              선물정보={선물정보}
              setShowCerti={setShowCerti}
              showCerti={showCerti}
            ></Card>
          )}
        </div>
      </Route>

      {/* <Route exact path='/testForgranted'>
        <div className='body-container height-80vh'>
          <Header></Header>
          <Slidebar></Slidebar>
          <Searchbar 선물정보변경={선물정보변경} ></Searchbar>
          <Footer></Footer>        
        </div>          
      </Route> */}
    </div>
  );
}

export default App;
