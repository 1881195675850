import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import "./Searchbar.css";
import QueryString from "qs";
import ReactGA from "react-ga";

export default function Searchbar(props) {
  let [입력값, 입력값변경] = useState("");
  let [검색가능여부, 검색가능여부변경] = useState(true);

  let location = useLocation();
  let history = useHistory();

  const queryData = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (queryData === Object && Object.keys(queryData).length === 0) {
    } else {
      입력값변경(queryData.pn);
    }
  }, []);

  return (
    <>
      <div className="search-bar">
        <div
          className={
            검색가능여부
              ? "search-bar-container"
              : "search-bar-container is-invalid"
          }
        >
          <input
            type="text"
            defaultValue={입력값}
            placeholder="기부번호를 입력해주세요"
            className={
              검색가능여부 ? "search-bar-input" : "search-bar-input is-invalid"
            }
            onChange={(e) => {
              입력값변경(e.target.value);
            }}
            onFocus={() => {
              검색가능여부변경(true);
            }}
            onClick={() => {
              ReactGA.event({
                category: "검색창",
                action: "검색창클릭",
                label: "home",
              });
            }}
          />
          <button
            className={
              검색가능여부
                ? "search-bar-button"
                : "search-bar-button is-invalid"
            }
            onClick={() => {
              ReactGA.event({
                category: "검색버튼",
                action: "검색버튼클릭",
                label: "home",
              });
              axios
                .get("https://forgranted.co.kr/search.forgranted?pn=" + 입력값)
                .then((result) => {
                  if (result.data.length === 0) {
                    검색가능여부변경(false);
                    console.log(result);
                    return;
                  } else {
                    console.log(result);
                    console.log(입력값);
                    props.선물정보변경(result.data[0]);

                    if (result.data[0].status === 0) {
                      검색가능여부변경(false);
                      return;
                    } else {
                      props.선물정보변경(result.data[0]);
                      history.push("/present");
                    }
                  }
                })
                .catch(() => {
                  console.log("internet error");
                });
            }}
          >
            검색
          </button>
        </div>
      </div>

      {검색가능여부 ? null : (
        <p className="search-bar-alert">선물번호를 다시 한번 확인해주세요</p>
      )}
    </>
  );
}
